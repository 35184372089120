import React from "react";
import { Link } from "gatsby";

const Footer = () => (
  <footer className="w-full relative z-10 mt-16 pb-8 bg-grey">
    <div className="w-full relative border-t-black">
      <div className="grid">
        <h3 className="grid-end-12 mt-6 mb-1 caption font-bold">
          <Link className="cursor-pointer" to="/">
            drewpettifer.com
          </Link>
        </h3>
      </div>
    </div>

    <div className="w-full relative border-t-black">
      <div className="grid">
        <ul className="grid-end-2 xs:grid-end-10 mt-2 xs:mt-4 flex flex-wrap">
          <li className="b1">
            <Link to="/info" className="mr-1 underline">
              Info,
            </Link>
          </li>

          <li className="b1">
            <Link to="/work" className="mr-1 underline">
              Work,
            </Link>
          </li>

          <li className="b1">
            <Link to="/contact" className="mr-1 underline">
              Contact,
            </Link>
          </li>

          <li className="b1">
            <Link to="/essays" className="mr-1 underline">
              Essays,
            </Link>
          </li>

          <li className="b1">
            <a
              href="https://www.instagram.com/drewpettifer/"
              className="underline"
              rel="noopener noreferrer"
              target="_blank"
            >
              Instagram
            </a>
          </li>
        </ul>

        <h3 className="footer__copyright grid-end-6 xs:grid-end-12 grid-start-7 xs:grid-start-1 mt-2 xs:mt-10 whitespace-no-wrap b1">
          © Drew Pettifer 2020 design by Lachlan Siu.
        </h3>
      </div>
    </div>
  </footer>
);

export default Footer;
