import React, { useContext } from "react";
import PropTypes from "prop-types";
import { AppContext } from "~context/AppContext";
import Footer from "~components/Footer";

const Layout = ({ children, className }) => {
  let { background } = useContext(AppContext);

  if (!background || background === `undefined`) {
    background = `white`;
  }

  return (
    <>
      <main id="layout" className={`layout ${className} bg-${background}`}>
        {children}

        <Footer />
      </main>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string.isRequired
};

export default Layout;
